<template>
	<unstyled-modal
		v-if="isFocused"
		:backdrop-color="!dark ? 'bg-gray-50' : 'bg-black'"
		:should-close="shouldClose"
		@close="handleClose()">
		<template #outside>
			<div
				:style="{ 'z-index': 999999999999 }"
				class="fixed right-5 top-5">
				<button @click.prevent="shouldClose = true">
					<XMarkIcon class="h-7 w-7 text-black transition-colors hover:text-gray-500" />
				</button>
			</div>
		</template>
		<div class="w-full">
			<img
				v-if="resourceType === 'image'"
				:src="imageUrl"
				alt="Focused Image"
				class="w-full rounded-md border border-gray-100 shadow-sm" />
			<video
				v-else
				:src="imageUrl"
				class="w-full rounded-md border border-gray-100 shadow-sm"
				controls></video>
		</div>
	</unstyled-modal>
	<div
		ref="container"
		class="cursor-pointer transition-all hover:brightness-95"
		role="button"
		@click.prevent="
			() => {
				shouldClose = false;
				isFocused = true;
			}
		">
		<slot />
	</div>
</template>
<script lang="ts" setup>
import { XMarkIcon } from "@heroicons/vue/24/solid";
import type { Ref } from "vue";

const isFocused = ref(false);
const shouldClose = ref(false);
const imageUrl = ref("");
const resourceType = ref("image") as Ref<"image" | "video">;
const container = ref() as Ref<HTMLDivElement>;

const emits = defineEmits(["open"]);

const props = defineProps<{
	dark?: boolean;
}>();

function handleScroll(e: any) {
	if (!isFocused.value) {
		return;
	}
	shouldClose.value = true;
}

function handleClose() {
	shouldClose.value = false;
	isFocused.value = false;
}

onMounted(() => {
	setTimeout(() => {
		document.addEventListener("scroll", handleScroll);
	}, 1000);

	const img = container.value!!.querySelector("img");

	if (img) {
		imageUrl.value = img.src;
		resourceType.value = "image";
	} else {
		const video = container.value!!.querySelector("video");
		if (video) {
			imageUrl.value = video.src;
			resourceType.value = "video";
		}
	}
});

onUnmounted(() => {
	document.removeEventListener("scroll", handleScroll);
});
</script>
